import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import QuestionAnswerService from 'services/QuestionAnswerService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: [],
    answers:[]
}

export const getAnswers = createAsyncThunk('question/getAnswers', async (params, { rejectWithValue }) => {
	
	try {
		const response = await QuestionAnswerService.getAnswers(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getQuestionAnswer = createAsyncThunk('question/getQuestionAnswer', async (params, { rejectWithValue }) => {
	
	try {
		const response = await QuestionAnswerService.getQuestionAnswer(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addQuestionAnswer = createAsyncThunk('question/addQuestionAnswer', async (data, { rejectWithValue }) => {
	
	try {
		const response = await QuestionAnswerService.addQuestion(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addQuestionTag = createAsyncThunk('question/addQuestionTag', async (data, { rejectWithValue }) => {
	
	try {
		const response = await QuestionAnswerService.addQuestionTag(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const changeStatus = createAsyncThunk('question/changeStatus', async (data, { rejectWithValue }) => {
	
	try {
		const response = await QuestionAnswerService.changeStatus(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const changeQuestionType = createAsyncThunk('question/changeQuestionType', async (data, { rejectWithValue }) => {
	
	try {
		const response = await QuestionAnswerService.changeQuestionType(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const questionAnswerSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getAnswers.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getAnswers.fulfilled, (state, action) => {
            state.loading = false
            state.answers = action.payload
            state.message = ''
        })
        .addCase(getAnswers.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getQuestionAnswer.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(getQuestionAnswer.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.message = ''
        })
        .addCase(getQuestionAnswer.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        }) 
        .addCase(addQuestionAnswer.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addQuestionAnswer.fulfilled, (state, action) => {
            state.loading = false
            state.data.all.unshift(action.payload)
            state.message = 'Question Added'
        })
        .addCase(addQuestionAnswer.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addQuestionTag.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(addQuestionTag.fulfilled, (state, action) => {
            state.loading = false
          //  state.data = action.payload
            state.message = 'Tags Added'
        //    let foundIndex = state.data.findIndex( data => data._id === action.payload._id);
        //     state.data.splice(foundIndex, 1, action.payload);
        })
        .addCase(addQuestionTag.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(changeStatus.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(changeStatus.fulfilled, (state, action) => {
            state.loading = false
           // state.data = action.payload
           // state.message = 'Status Changed'
            let foundIndex = state.data?.all.findIndex( data => data._id === action.payload._id);
            state.data?.all.splice(foundIndex, 1, action.payload);
        })
        .addCase(changeStatus.rejected, (state, action) => {
            state.message = action.payload
            //state.showMessage = true
            state.loading = false
        })
        .addCase(changeQuestionType.pending, (state) => {
            state.loading = true
            state.message = ''
        })
        .addCase(changeQuestionType.fulfilled, (state, action) => {
            state.loading = false
           // state.data = action.payload
            state.message = 'Question Type Changed'
            // let foundIndex = state.data?.all.findIndex( data => data._id === action.payload._id);
            // state.data?.all.splice(foundIndex, 1, action.payload);
        })
        .addCase(changeQuestionType.rejected, (state, action) => {
            state.message = action.payload
            //state.showMessage = true
            state.loading = false
        })
       
    }
});



export default questionAnswerSlice.reducer;