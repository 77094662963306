import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import GroupService from 'services/GroupService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
    data: [],
    resData: [],
    resError: false,
    resStatus: false,
    groupLeader:[],
    singleGroupLeader:[],
    groupData:[],
    singleGroup:[],
    groupLeaderGroup:[],
    groupParticipant:[],
    groupLeaderParticipant: [],
    allGroupLeader: [],
    allGroupLeaderParticipant: [],
    allGroup: [],
}


export const getGroupLeader = createAsyncThunk('group/getGroupLeader', async (params, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getGroupLeader(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const getSingleGroupLeader = createAsyncThunk('group/getSingleGroupLeader', async (id, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getSingleGroupLeader(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getGroup = createAsyncThunk('group/getGroup', async (params, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getGroup(params)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});


export const getSingleGroup = createAsyncThunk('group/getSingleGroup', async (id, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getSingleGroup(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getGroupLeaderGroup = createAsyncThunk('group/getGroupLeaderGroup', async (id, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getGroupLeaderGroup(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});
export const getGroupParticipant = createAsyncThunk('group/getGroupParticipant', async (id, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getGroupParticipant(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getGroupLeaderParticipant = createAsyncThunk('group/getGroupLeaderParticipant', async (id, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getGroupLeaderParticipant(id)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const groupLeaderRegistration = createAsyncThunk('group/groupLeaderRegistration', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.groupLeaderRegistration(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addGroupLeaderParticipation = createAsyncThunk('group/addGroupLeaderParticipation', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.addGroupLeaderParticipation(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const createGroup = createAsyncThunk('group/createGroup', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.createGroup(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addParticipationToGroup = createAsyncThunk('group/addParticipationToGroup', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.addParticipationToGroup(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllGroupLeader = createAsyncThunk('group/getAllGroupLeader', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getAllGroupLeader(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllGroupLeaderParticipant = createAsyncThunk('group/getAllGroupLeaderParticipant', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getAllGroupLeaderParticipant(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const getAllGroup = createAsyncThunk('group/getAllGroup', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.getAllGroup(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const addGroupToProgram = createAsyncThunk('group/addGroupToProgram', async (data, { rejectWithValue }) => {
	
	try {
		const response = await GroupService.addGroupToProgram(data)
		if (response.status) {
			console.log(response);
            return response.data
		} else {
			return rejectWithValue(response.message?.replace('Firebase: ', ''));
		}
	} catch (err) {
		return rejectWithValue(err.message || 'Error')
	}
});

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getGroupLeader.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getGroupLeader.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.groupLeader = action.payload
        })
        .addCase(getGroupLeader.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
     
        .addCase(getSingleGroupLeader.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getSingleGroupLeader.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.singleGroupLeader = action.payload
        })
        .addCase(getSingleGroupLeader.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getGroup.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getGroup.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.groupData = action.payload
        })
        .addCase(getGroup.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
     
        .addCase(getSingleGroup.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getSingleGroup.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.singleGroup = action.payload
        })
        .addCase(getSingleGroup.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getGroupLeaderGroup.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getGroupLeaderGroup.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.groupLeaderGroup = action.payload
        })
        .addCase(getGroupLeaderGroup.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getGroupParticipant.pending, (state) => {
            state.resData= []
            state.loading = true
            state.resStatus= false
        })
        .addCase(getGroupParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.groupParticipant = action.payload
        })
        .addCase(getGroupParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getGroupLeaderParticipant.pending, (state) => {
            state.resData= []
            state.loading = true
            state.resStatus= false
        })
        .addCase(getGroupLeaderParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.groupLeaderParticipant = action.payload
        })
        .addCase(getGroupLeaderParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(groupLeaderRegistration.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(groupLeaderRegistration.fulfilled, (state, action) => {
            state.loading = false
            state.resData = action.payload
            state.message = 'Group Leader Registered'
            
        })
        .addCase(groupLeaderRegistration.rejected, (state, action) => {
            state.message = action.payload
            //state.showMessage = true
            state.loading = false
        })
        .addCase(addGroupLeaderParticipation.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(addGroupLeaderParticipation.fulfilled, (state, action) => {
            state.loading = false
            state.message='Participant Added'
            state.resData = action.payload
        })
        .addCase(addGroupLeaderParticipation.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(createGroup.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(createGroup.fulfilled, (state, action) => {
            state.loading = false
             state.message = 'Group Created'
            state.resData = action.payload
        })
        .addCase(createGroup.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addParticipationToGroup.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(addParticipationToGroup.fulfilled, (state, action) => {
            state.loading = false
            state.message='Participant Added'
            state.resData = action.payload
        })
        .addCase(addParticipationToGroup.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllGroupLeader.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getAllGroupLeader.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.allGroupLeader = action.payload
        })
        .addCase(getAllGroupLeader.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllGroupLeaderParticipant.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getAllGroupLeaderParticipant.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.allGroupLeaderParticipant = action.payload
        })
        .addCase(getAllGroupLeaderParticipant.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(getAllGroup.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(getAllGroup.fulfilled, (state, action) => {
            state.loading = false
            state.message=''
            state.allGroup = action.payload
        })
        .addCase(getAllGroup.rejected, (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        })
        .addCase(addGroupToProgram.pending, (state) => {
            state.loading = true
            state.resStatus= false
        })
        .addCase(addGroupToProgram.fulfilled, (state, action) => {
            state.loading = false
            state.message='Group Added to Program' 
        })
        .addCase(addGroupToProgram.rejected, (state, action) => {
            state.message = action.payload
           // state.showMessage = true
            state.loading = false
        })
    }
});


export default groupSlice.reducer;