import fetch from 'auth/FetchInterceptor'
import {API_BASE_URL} from '../configs/AppConfig'
const GroupService = {}


GroupService.getGroupLeader = function (data) {
	let {page, searchGroupLeader} = data
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-group-leader',
		method: 'get',
        params: {
            'offset' : offset,
			searchGroupLeader,
        }
	})
};

GroupService.getSingleGroupLeader = function (id) {
	//let offset = (params-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-single-group-leader/${id}`,
		method: 'get',
       
	})
};


GroupService.getGroup = function (data) {
	let {page, searchGroup} = data
	let offset = (page-1)*10
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-group',
		method: 'get',
        params: {
            'offset' : offset,
			searchGroup,
        }
	})
};

GroupService.getSingleGroup = function (id) {
	//let offset = (params-1)*10
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-single-group/${id}`,
		method: 'get',
       
	})
};

GroupService.getGroupLeaderGroup = function (data) {
	let {id, page} = data;
   let offset = (page-1)*10
   return fetch({
	   url: API_BASE_URL+`/v1/admin/get-group-leader-group/${id}`,
	   method: 'get',
	   params: {
		   'offset': offset
	   },

   })
};

GroupService.getGroupParticipant = function (data) {
	let {id, page} = data;
   let offset = (page-1)*10
   return fetch({
	   url: API_BASE_URL+`/v1/admin/get-group-participant/${id}`,
	   method: 'get',
	   params: {
		   'offset': offset
	   },

   })
};

GroupService.getGroupLeaderParticipant = function (data) {
	let {id, page} = data;
   let offset = (page-1)*10
   return fetch({
	   url: API_BASE_URL+`/v1/admin/get-group-leader-participant/${id}`,
	   method: 'get',
	   params: {
		   'offset': offset
	   },

   })
};

GroupService.groupLeaderRegistration = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/group-leader-registration`,
		method: 'post',
		data
		
	})
};

GroupService.addGroupLeaderParticipation = function (data) {
	const {id, participant_data} = data;
	return fetch({
		url: API_BASE_URL+`/v1/admin/add-group-leader-participant/${id}`,
		method: 'post',
		data: participant_data
	})
};

GroupService.createGroup = function (data) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/create-group`,
		method: 'post',
		data
		
	})
};

GroupService.addParticipationToGroup = function (data) {
	const {id, participant_data} = data;
	return fetch({
		url: API_BASE_URL+`/v1/admin/add-participant-to-group/${id}`,
		method: 'post',
		data: participant_data
	})
};

GroupService.getAllGroupLeader = function (data) {
	return fetch({
		url: API_BASE_URL+'/v1/admin/get-all-group-leader',
		method: 'get',
	})
};

GroupService.getAllGroupLeaderParticipant = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-group-leader-participant/${id}`,
		method: 'get',
	})
};

GroupService.getAllGroup = function (id) {
	return fetch({
		url: API_BASE_URL+`/v1/admin/get-all-group/${id}`,
		method: 'get',
	})
};

GroupService.addGroupToProgram = function (data) {
	const {id, group} = data;
	return fetch({
		url: API_BASE_URL+`/v1/admin/add-group-to-program/${id}`,
		method: 'post',
		data: group
	})
};


export default GroupService;